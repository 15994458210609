@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body{
  margin: 0px;
  font-family:'Poppins', sans-serif;
  background: var(--bg);
  color: var(--black);
  transition: all 3ms ease;
  scroll-behavior: var(--pink);
}
